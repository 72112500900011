import {
  sysUsers,
  delGroupByGroupId,
  saveSysUserRole,
  findGroupByClassId,
} from "./classGroupApi";
import { ElMessage } from "element-plus";
import { dealDate } from "../../course-manage/trade-manage/tradeMethods";
import { ElMessageBox } from "element-plus";
import { findFunds } from "../../course-manage/trade-manage/components/TradeDetails/detailsApi";

export const getUserLists = (data) => {
  data.loading = true;
  let parame = {
    classId: sessionStorage.getItem("classId"),
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  findGroupByClassId(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTable = newRes.groupCoursePageInfo.list;
      data.total = newRes.groupCoursePageInfo.total;
      dealDate(data.spreadTable, ["createTime"]);

      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getUserLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getUserLists(data);
};

export const delDrafrFiles = (item, data) => {
  let parame = {
    groupId: item.courseGroupId,
  };
  delGroupByGroupId(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

export const clickButtons = (val, data, router) => {
  if (val.type === "删除") {
    ElMessageBox.confirm("此操作将永久删除该小组, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      delDrafrFiles(val.item, data);
    });
  }
  if (val.type === "修改余额") {
    modifyBalance(val.item, data);
  }
  if (val.type === "查看") {
    checkGroup(val.item, data, router);
  }
};

// 查看该组
export const checkGroup = (val, data, router) => {
  sessionStorage.setItem("acid", val.acid);
  sessionStorage.setItem("groupId", val.courseGroupId);
  sessionStorage.setItem("courseUserId", "1");
  router.push("/course/home");
};

// 设置角色
export const modifyBalance = (val, data) => {
  getMoneys(val, data);
  data.showRoleDia = true;
  data.userItem = val;
};

export const getMoneys = (val, data) => {
  let num = 0;
  findFunds({
    acid: val.acid,
    exchange: num,
  }).then((res) => {
    if (res.data.code === "200") {
      data.setMoney = res.data.data.funds[0].freeAmount;
    }
  });
};
// 确认修改
export const saveInfos = (data) => {
  let parame = {
    id: data.userItem.id,
    userRoleId: data.userValue,
  };
  saveSysUserRole(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      data.userItem = {};
      backInfo(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};
// 取消修改
export const backInfo = (data) => {
  data.setMoney = "";
  data.userItem = {};
  data.showRoleDia = false;
};

// 查找所有角色
export const getRoleList = (data) => {
  let parame = {
    pageSize: 1000,
    pageIndex: 1,
  };
  sysRoles(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.userOptions = newRes.sysRolePageInfo.list;
    }
  });
};
